import React, { useState } from "react"

import Layout from "../components/layout"
import Explainer from '../assets/png/video/365BOX-Explainer.png'
import Installation from '../assets/png/video/365BOX-Installation.png'
import Shortcuts from '../assets/png/video/365BOX-Shortcuts.png'
import Tour from '../assets/png/video/365BOX-Tour.png'
import { Modal } from "react-responsive-modal"

const Video = () => {
  return (
    <Layout>
     
      <div className="mt-48">
        <div className="grid grid-cols-1 md:grid-cols-2 px-8 md:px-16 mt-8 max-w-screen-xl mx-auto">
            <VideoItem thumb={Explainer} video={"https://www.youtube.com/embed/aiRLejymIn8"} />
            <VideoItem thumb={Installation} video={"https://www.youtube.com/embed/RaTpreA0v7Q"}  />
            <VideoItem thumb={Shortcuts} video={"https://www.youtube.com/embed/aiRLejymIn8"} />
            <VideoItem thumb={Tour} video={"https://www.youtube.com/embed/RaTpreA0v7Q"} />
        </div>
      </div>
    </Layout>
  )
}

const VideoItem = ({ thumb, video }) => {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)
  return (
      <div className="space-y-3 p-4">
         <Modal
          open={open}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
          onClose={onCloseModal}
          center
        >
          <iframe
            className="aspect-video w-full"
            src={video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal>
        <button onClick={onOpenModal} onKeyDown={onOpenModal}>
           <img alt="365BOX SharePoint Online Intranet" src={thumb} />
          </button>
      </div>
  )
}

export default Video
